<template>
  <div class="container">
    <div class="search-bar">
      <div class="flex flex-align-center">
        <div style="font-size:18px;color:rgba(41,41,41,1);">
          <b>设计公司展厅</b>
        </div>
        <el-input
          v-model="searchText"
          placeholder="请输入设计公司名称"
          @change="handleChanged"
          style="max-width: 500px;margin-left: 50px;"
        >
          <!-- <el-select v-model="query.type" slot="prepend" placeholder="请选择">
                      <el-option label="工厂款" value="1"></el-option>
                      <el-option label="设计师款" value="2"></el-option>
          </el-select>-->
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
          <!-- <el-button @click="search()" slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
      </div>
    </div>
    <div class="header">
      <div style="width:1200px;margin:0 auto;">
        <!-- 头部筛选条件 -->
        <SortNav type="1" :showMore="true" :query="query" :total="pageConfig.total" />
      </div>
    </div>
    <div class="page-body">
      
      <!-- 内容 -->
      <div class="list" ref="load" style="min-height:550px">
        <factory-block 
        v-for="item in factoryList" :key="item.id"
        :item="item" 
        :zoneList="zoneList"
        @showPhone="showPhone"
        />
      </div>

      <el-pagination
          style="background:#fff;padding:10px 0;margin-top:16px"
          background
          layout="prev, pager, next"
          @current-change="pageChanged()"
          :current-page.sync="pageConfig.page"
          :page-size="pageConfig.pagesize"
          :total="pageConfig.total"
        >
        </el-pagination>
    </div>

    <el-dialog width="500px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
        <div class="cope-phone">
          <i class="iconfont icon-dianhuabeifen"></i>
          <span>{{phone}}</span>
          <a @click="copy(phone)">复制</a>
        </div>
        <div style="text-align:center;">
          <el-button @click="hide()" size="small" type="primary">好的</el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import { getListDesign, getFactoryZones } from "@/service/company";
import SortNav from "./sortNav";
import FactoryBlock from "./factory-block";
export default {
  components: {
    SortNav,FactoryBlock
  },
  data() {
    return {
      popTitle:null,
      popVisible:false,
      phone:null,
      pageConfig: {
        page: 1,
        pagesize: 10,
        total: 10,
      },
      isSearch: true,
      tags: [],
      more: {},
      searchText: "",
      factoryList: [
        {
          id: 1,
          name: "江西飞鑫家具有限公司",
          onsale: 37,
          aptitude: {
            ability_service: {
              jgsx: [{ k: "111", v: "外贸订单" }],
              mczl: [{ k: "111", v: "外贸订单" }],
              zdcp: [{ k: "111", v: "外贸订单" }],
              wx: [{ k: "111", v: "外贸订单" }],
              fwbq: [{ k: "111", v: "外贸订单" }]
            },
            stat: {
              n_employee: 0,
              reg_date: "1022-12-21",
              area: 100,
              amount: 100,
              n_equipment: 2,
              zone: 0,
              photo: [
                // require("../../../assets/images/cloudworks/feixinjiaju/feixinjiaju1.jpg"),
                // require("../../../assets/images/cloudworks/feixinjiaju/feixinjiaju2.jpg"),
                // require("../../../assets/images/cloudworks/feixinjiaju/feixinjiaju3.jpg"),
                // require("../../../assets/images/cloudworks/feixinjiaju/feixinjiaju4.jpg")
              ]
            },
            ability_make: [
              {
                name: "喷漆"
              },
              {
                name: "锣机"
              },
              {
                name: "拼版"
              }
            ],
            real_check: {
              expired_at: 12321321,
              stat: 1
            }
          }
        }
      ],
      zoneList:[],
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    }
  },
  async created() {
    // this.zoneList = await getFactoryZones();
    // for (let i in this.zoneList) {
    //   //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
    //   this.zoneList[this.zoneList[i].id] = this.zoneList[i];
    // }
  },
  mounted() {},
  methods: {
    //渲染家具工厂表格数据
    renderFurnitureFactory() {
      this.factoryList = [];
      const _loading = this.$loading({
        target: this.$refs["load"],
        lock: true,
        text: "加载中...",
      });
      this.getFurnitureFactory().then((data) => {
        console.log(data.list, data.list[0])
        this.factoryList = data.list
        this.pageConfig.total = data.total
        _loading.close();
      }).catch(err => {
        _loading.close();
        console.log(err);
        this.$message.error(err.message);
      });
    },
    async getFurnitureFactory() {
      let res = []
      const param = {
        n_employee: this.more.n_employee, // 筛选人数
        n_reg_date: this.more.n_reg_date, // 筛选成立年限
        cert_stat: this.more.cert_stat, //是否认证
        onsale: this.more.onsale, //是否有在售商品
        fname: this.searchText, //搜索名字
        page: this.pageConfig.page,
        pagesize: this.pageConfig.pagesize,
      }
      await getListDesign(param).then((data) => {
        res = data
      })
      return res
    },
    showPhone(phone) {
      this.phone = phone;
      this.popTitle = "联系方式";
      this.popVisible = true;
    },
    hide() {
      this.popVisible = false;
    },
    copy(data){
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      // console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success('复制成功');
      oInput.remove();
    },
    //搜索条件改变时触发的事件
    handleChanged() {
      this.pageConfig.page = 1;
      this.renderFurnitureFactory();
    },
    pageChanged() {
      this.renderFurnitureFactory();
    },
    query(tags, more) {
      console.log("cloundWords", tags, more);

      this.tags = JSON.parse(JSON.stringify(tags));
      this.more = JSON.parse(JSON.stringify(more));
      // 上一次请求是搜索 筛选重置为第一页
      this.pageConfig.page = 1;

      if (this.more.cert_stat) {
        this.more.cert_stat = 1
      } else this.more.cert_stat = 0;
      if (this.more.onsale) {
        this.more.onsale = 1
      } else this.more.onsale = 0;

      // this.tags.map(n => {
      //   if (n.title === "工艺能力") {
      //     this.pageConfig.gynl = n.id.split(",");
      //   } else if (n.title === "生产属性") {
      //     this.pageConfig.jgsx = n.id.split(",");
      //   } else {
      //     this.pageConfig.mczl = n.id.split(",");
      //   }
      // });

      this.renderFurnitureFactory()
    },
    search(str, page = 1, pagesize = 10) {
      // 上次是筛选 搜索重置为第一页
      if (!this.isSearch) {
        this.isSearch = true;
        this.page = 1;
        page = 1;
      }
      let data = {
        page,
        pagesize
      };
      if (str) {
        data.key = str;
      }
      // postToPortalServer("/factory-search", data).then(res => {
      //   if (res.errcode === 0) {
      //     this.factoryList = res.result.list;
      //     this.total = res.result.total;
      //     this.updateTotal(this.total);
      //   }
      // });
    },
    change(page, pageSize) {
      if (this.isSearch) {
        this.search(this.searchText, page, pageSize);
      } else {
        this.query(this.tags, this.more);
      }
    },
    showSizeChange(page, pageSize) {
      console.log(page, pageSize);
      if (this.isSearch) {
        this.search(this.searchText, page || 1, pageSize);
      } else {
        this.query(this.tags, this.more);
      }
    }
  }
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.search-bar {
  min-width: 1200px;
  background: #fff;
  padding: 30px 0;
  border-bottom: 2px solid #eee;
}
.search-bar > div {
  width: 1200px;
  margin: 0 auto;
}
.search-bar > div >>> .el-select .el-input {
  width: 130px;
}
.header {
  width: 100%;
  background: #fff;
}
</style>
<style scoped lang="less">
.page-body {
  width: 1200px;
  // height: calc(100% - 102px);
  // overflow-y: auto;
  margin: 20px auto;
  // padding: 0 30px;
  // background: #FFFFFF;
  // border-left: 1px solid #CCCCCC;
  // border-right: 1px solid #CCCCCC;
  .el-tag {
    line-height: 22px;
    height: 22px;
  }
  .box-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .search-event-item {
      width: 100%;
      padding: 15px 60px;
      background: #fff;
      > div {
        margin-right: 40px;
        & + div {
          margin-left: 56px;
        }
      }
    }
  }
}
.cope-phone {
  width: 180px;
  margin: 0 auto 20px;
  padding: 30px 0;
  font-size: 14px;
  color: #4D4D4D;
  i {
    margin-right: 10px;
    color: #07c160;
  }
  span {
    margin-right: 30px;
  }
}
</style>