<template>
    <div class="img-item" :style="'width:'+width+'px;height:'+height+'px;'">
        <div v-if="list[idx].photo.length > 1" class="prev" @click="prev(idx)">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div v-if="list[idx].photo.length > 1" class="next" @click="next(idx)">
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="img-wrap img-wrap-1">
            <div class="inner flex"
                :style="{transform: 'translateX(' + translateX[idx] + 'px)'}">
                <img v-for="(photo, j) in list[idx].photo" :key="j" :src="IMG_URL_PRE+photo+'!width_250px'" />
            </div>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE } from "@/config";
    export default {
        props: ['list','idx','width','height'],
        data() {
            return {
                IMG_URL_PRE,
                index: [],
                translateX: [],
            }
        },
        watch: {
            type:{
                handler() {
                    
                },
                deep: true
            },
        },
        computed: {},
        created () {
            if (this.list && this.list.length>0) {
                this.index = Array(this.list.length).fill(0);
                this.translateX = Array(this.list.length).fill(0);
            }
        },
        methods: {
            prev(i) {
                if (this.index[i] === 0) {
                    return;
                }
                this.index[i]--;
                // const wrap = document.querySelector(".img-wrap-1");
                // const W = wrap.offsetWidth;
                // this.translateX[i] += W;
                this.translateX[i] += parseFloat(this.width);
                this.$forceUpdate();
            },
            next(i) {
                if (this.index[i] === this.list[i].photo.length - 1) {
                    return;
                }
                this.index[i]++;
                // const wrap = document.querySelector(".img-wrap-1");
                // const W = wrap.offsetWidth;
                // this.translateX[i] -= W;
                this.translateX[i] -= parseFloat(this.width);
                this.$forceUpdate();
            }
        }
    }
</script>
<style scoped lang="less">
.img-item {
    position: relative;
    overflow: hidden;
    &:last-child {
        margin-right: 0;
    }
    &:hover .prev {
        opacity: 1;
    }
    &:hover .next {
        opacity: 1;
    }
    .prev,
    .next {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        position: absolute;
        box-shadow: 0 0 6px #ccc;
        background-color: #fff;
        font-size: 22px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        font-weight: bold;
        z-index: 2;
        transition: all 0.3s;
    }
    .prev {
        top: calc(50% - 16px);
        left: 5px;
        opacity: 0;
    }
    .next {
        top: calc(50% - 16px);
        right: 5px;
        opacity: 0;
    }
    .img-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .inner {
            height: 100%;
            transition: transform 0.3s;
            flex-wrap: nowrap;
            img {
                width: 100%;
                flex-shrink: 0;
            }
        }
    }
}
</style>