<template>
    <div class="phone">
        <div style="padding: 30px 0;">
            <div v-if="phone" class="cope-phone">
            <i class="iconfont icon-zu10" style="color:#5074EE;"></i>
            <span>{{name}}</span>
            </div>
            <div v-if="phone" class="cope-phone">
            <i class="iconfont icon-dianhuabeifen"></i>
            <span>{{phone}}</span>
            <a @click="copy(phone)">复制</a>
            </div>
        </div>
        <div style="text-align:center;">
          <el-button @click="hide()" size="small" type="primary">好的</el-button>
        </div>
    </div>
</template>

<script>
    import { getPhone } from '@/service/company';
    export default {
        props: ['compId','type','popTitle'],
        data() {
            return {
                phone:null,
                name:"",
            }
        },
        watch: {
            type:{
                handler() {
                    this.getPhone();
                },
                deep: true
            },
        },
        computed: {},
        created () {
            // console.log(this.compId);
            this.getPhone();
        },
        methods: {
            getPhone() {
                let obj = {
                    comp_id: this.compId,
                    channel: this.type
                };
                getPhone(obj).then(rst=>{
                    this.phone = rst.mobile;
                    this.name = rst.name;
                    console.log(rst);
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            hide() {
                this.$emit("hide");
            },
            copy(data){
                let url = data;
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                // console.log(oInput.value)
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message.success('复制成功');
                oInput.remove();
            },
        }
    }
</script>
<style scoped lang="less">
.cope-phone {
  width: 180px;
  margin: 0 auto 20px;
//   padding: 30px 0;
  font-size: 14px;
  color: #4D4D4D;
  i {
    margin-right: 10px;
    color: #07c160;
  }
  span {
    margin-right: 30px;
  }
}
</style>